<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <BrandPlaceForm
      v-if="!isLoading"
      :brandPlace="brandPlace"
      v-on:addOrUpdateBrandPlace="addOrUpdateBrandPlace()"
      :submitName="$t('edit')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import BrandPlaceForm from "@/components/brandPlaces/BrandPlaceForm.vue";
import BrandPlace from "@/models/brandPlaces/BrandPlace";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    BrandPlaceForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      brandPlace: new BrandPlace(),
    };
  },
  props: ["brandPlaceToken"],
  methods: {
    async addOrUpdateBrandPlace() {
      this.isLoading = true;
      try {
        const response = await this.brandPlace.addOrUpdateBrandPlace(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.brandPlace.setInitialValue();
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "BrandPlaces" }).catch(() => {});
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getBrandPlaceDetails() {
      this.isLoading = true;
      this.brandPlace.brandPlaceToken = this.brandPlaceToken;
      try {
        let response = await this.brandPlace.getBrandPlaceDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.brandPlace.fillData(response.data.brandPlaces);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "BrandPlaces" }).catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router.push({ name: "BrandPlaces" }).catch(() => {});
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getBrandPlaceDetails();
  },
};
</script>
